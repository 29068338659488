<script context="module">
	import {
		initializeCookiesStore,
		setMeta,
		startLoading,
		stopLoading
	} from '$lib/stores/app/actions';
	import Cookies from 'js-cookie';
	import { app } from '$lib/stores/app/store';
	import GDPRBanner from '$lib/components/BannerGDPR.svelte';
	import { COOKIES, COUNTRY_CODES_GDPR } from '$lib/utils/constants';
	// TODO: find a better alternative for
	// environment detection
	if (typeof window !== 'undefined') {
		setMeta(Cookies.get());
	}
</script>

<script>
	import '../app.css';
	import { browser } from '$app/environment';
	import { afterNavigate, beforeNavigate } from '$app/navigation';
	import { Modals, closeAllModals, closeModal, openModal } from 'svelte-modals';
	import Loading from '$lib/components/ComponentLoading.svelte';
	import Authorizer from '$lib/services/auth';
	import { AnalyticsService } from '$lib/services/analytics';
	import { onMount } from 'svelte';
	import * as Sentry from '@sentry/browser';
	import { loadThirdPartyScripts } from '$lib/utils/third-party.js';
	import { page } from '$app/stores';

	if (Cookies.get(COOKIES.GDPR) !== 'hide' && !Cookies.get('wdio_block_gdpr_banner')) {
		COUNTRY_CODES_GDPR.hasOwnProperty(Cookies.get(COOKIES.COUNTRY_CODE))
			? Cookies.set(COOKIES.GDPR, 'show', COOKIES.CONFIG)
			: '';
	}

	beforeNavigate(async (nav) => {
		if (nav.to) {
			const policies = import.meta.glob('./**/-policy.*');
			const nextPage = nav.to?.route.id;
			const isEligiblePage = !['/', '/prospect-register', '/subscription'].includes(nextPage ?? '');
			if (nextPage && isEligiblePage) {
				startLoading({ darkMode: true, timerMode: true });
				const authorizer = new Authorizer({ modules: policies, routeId: nextPage });
				await authorizer.browserAuthorize();
				stopLoading();
			}
		}
	});

	afterNavigate(async (nav) => {
		const prevPage = nav.from?.route.id;
		const nextPage = nav.to?.route.id;

		Sentry.addBreadcrumb({
			category: 'navigation',
			message: `Navigated from ${prevPage} to ${nextPage}`,
			level: 'info'
		});

		const isEligiblePage = !['/', '/prospect-register', '/subscription'].includes(nextPage ?? '');
		// No previous page implies a full-page reload.
		// https://kit.svelte.dev/docs/modules#$app-navigation
		if (!prevPage && nextPage && isEligiblePage) {
			startLoading({ darkMode: true, timerMode: true });
			const policies = import.meta.glob('./**/-policy.*');
			const authorizer = new Authorizer({ modules: policies, routeId: nextPage });
			await authorizer.browserAuthorize();
			stopLoading();
		}
		AnalyticsService.afterNavigate();
	});

	onMount(async () => {
		initializeCookiesStore();
		AnalyticsService.onMount();
		window.addEventListener('pageshow', (e) => {
			if (e.persisted) closeAllModals();
		});

		// const shouldAddAnalyticsScripts =
		// 	browser &&
		// 	!window?.navigator?.globalPrivacyControl &&
		// 	!$app.meta.inApp &&
		// 	$app.global.analyticsCookiesEnabled &&
		// 	$app.global.targetingCookiesEnabled;

		// if (shouldAddAnalyticsScripts) {
		// 	loadThirdPartyScripts();
		// }
	});

	// TODO: consider if this is an appropriate place to add modals.
	$: if ($app.global.loading) {
		openModal(Loading);
	} else {
		closeModal();
	}
</script>

<!-- TODO: move this to be config driven: debug build -->
<svelte:head>
	<!-- Preventing SEO listing for all pages in this application -->
	<meta name="robots" content="noindex" />
	<!-- //TODO fix: for home, pathname is resolving to '_home'; should be '/' -->
	<link rel="canonical" href={`https://www.plus.abcmouse.com${$page?.url?.pathname}`} />

	<!-- Partytown implementation -->
	<!-- <script>
		  partytown = {
			  forward: ['dataLayer.push'],
			  lib: '/partytown/'
		  }
	  </script> -->
	<!-- {@html "<script>" + partytownSnippet() + "</script>"} -->
	<!-- Google Tag Manager -->
	<script>
		(function (w, d, s, l, i) {
			w[l] = w[l] || [];
			w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
			var f = d.getElementsByTagName(s)[0],
				j = d.createElement(s),
				dl = l != 'dataLayer' ? '&l=' + l : '';
			j.async = true;
			j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
			f.parentNode.insertBefore(j, f);
		})(window, document, 'script', 'dataLayer', 'GTM-M2LGKK8Z');
	</script>
</svelte:head>

<slot />

<Modals>
	<div
		slot="backdrop"
		class="backdrop"
		class:dark-mode={$app.global.loadingDarkMode}
		class:z-102={$app.global.loading}
		role="button"
		aria-pressed="false"
		tabindex="0"
		on:click={() => window.dispatchEvent(new CustomEvent('global:backdrop-click'))}
		on:keydown={() => window.dispatchEvent(new CustomEvent('global:backdrop-click'))}
	/>
</Modals>

{#if $app.global.gdprVisible}
	<GDPRBanner />
{/if}
